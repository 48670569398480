<template lang="html">
    <!-- 人员 新建 弹出 -->
    <el-dialog :visible="personnelVisible" :title="title" :modal-append-to-body="true" :append-to-body="true" :close-on-click-modal="false" width="500px" @close="close()">
        <el-form  ref="formAuth"  :rules="formRules" :model="formData" label-position="top" :label-width="formLabelWidth" inline size="small" class="alertForm">
            <el-form-item label="姓名" prop="name" style="width:100%">
                <el-input v-model="formData.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="用户名" prop="username" style="width:100%">
                <el-input v-model="formData.username" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" :prop="formData.id?'':'password'" style="width:100%">
                <el-input type="password" v-model="formData.password" autocomplete="off" placeholder="不修改留空即可"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="close()">取 消</el-button>
            <el-button size="small" type="primary" @click="submit()">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            formData: {}, //提交数据
            formLabelWidth: '80px', //弹出form label 宽
            personnelVisible: false, //显隐
            // 表单验证
            formRules: {
                name: [{
                    required: true,
                    message: '请输入姓名',
                    trigger: 'blur'
                }],
                username: [{
                    required: true,
                    message: '请输入用户名',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: '请输入密码',
                    trigger: 'blur'
                }],
            },
        }
    },
    props: [
        'title',
    ],
    methods: {
        close: function() {
            this.personnelVisible = false;
            this.$emit('close');
        },
        open: function() {
            this.personnelVisible = true;
        },
        success: function() {
            this.$emit('success');
        },

        submit: function() {
            // 验证
            this.$refs['formAuth'].validate((valid) => {
                if (valid) {
                    this.formData.password=this.formData.password?this.$md5(this.formData.password):'';
                    this.http.post('/admin.admin/edit', this.formData).then(() => {
                        this.close();
                        this.success(); //父页面刷子你列表
                        this.formData = {}; //清空
                        this.$message({
                            message: '提交成功!',
                            type: 'success'
                        });
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>
