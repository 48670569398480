<template lang="html">
    <div style="height:100%">
        <!-- 第一行 搜索栏 -->
        <div class="handleBox flex flex-jc-sb flex-ai-c" @keyup.enter="curr=1;getList()">
            <p class="title">人员管理 ></p>
            <el-input placeholder="搜索用户名" v-model="searchValue" size="small" class="input-with-select" style="width:300px">
                <el-button slot="append" icon="el-icon-search" @click="curr=1;getList()"></el-button>
            </el-input>
            <div class="">
                <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" style="margin-right:10px" @click="edit()">添加人员</el-button>
            </div>
        </div>
        <!-- 第二行 表格操作栏 -->
        <!-- <div class="selectionBar"> -->
            <!-- <div>
                <label>场景：</label>
                <el-select v-model="select.role_id" placeholder="选择角色" clearable style="width:140px" @change="curr=1;getList()">
                    <el-option v-for="(item,index) in dictionaryList.roleList" :label="item" :value="index" :key="index"></el-option>
                </el-select>
            </div> -->
        <!-- </div> -->
        <!-- 表格 -->
        <div class="table" style="height:calc( 100% - 95px )">
            <el-table :data="list" highlight-current-row stripe border
                :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}"
                @sort-change='getList()'
                height="calc( 100% - 41px )"
            >
                <el-table-column prop="name" label="姓名" fixed show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="username" label="用户名" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间" min-width="110" show-overflow-tooltip>
                </el-table-column>
                <el-table-column label="操作" align="center" width="150">
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="edit(scope.row)">编辑</el-button>
                        <el-button type="danger" plain size="mini" @click="del(scope.row.id)" :disabled="scope.row.id==1?true:false">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>

        <personnelAdd ref="personnelAdd" :title="title" @success="getList()"></personnelAdd>
    </div>
</template>

<script>
import personnelAdd from "../components/personnelAdd.vue";
export default {
    components: {
        personnelAdd
    },
    data: function() {
        return {
            title: '添加人员',
            searchValue: '', //导入input值
            list: [],
            curr: 1, //页码
            row: 20, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted: function() {
        this.getList()
    },
    watch: {

    },
    methods: {
        getList: function() {
            this.http.post('/admin.admin/getList', {
                curr: this.curr,
                row: this.row,
                search:this.searchValue
            }).then(re => {
                this.list = re.list;
                this.curr = re.curr;
                this.pages = re.pages;
                this.count = re.count;
            })
        },
        edit: function(row) {
            if (row) {
                this.title='编辑人员';
                this.http.post('/admin.admin/read', {
                    id: row.id
                }).then(re => {
                    this.$refs.personnelAdd.formData = {
                        name:re.name,
                        username:re.username,
                        password:'',
                        id:re.id
                    }
                })
            } else {
                this.title='添加人员';
                this.$refs.personnelAdd.formData = {}
            }
            this.$refs.personnelAdd.open();
        },
        // 删除
        del(id) {
            this.$confirm('确定删除选中行吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.http.post('/admin.admin/delete', {
                    id
                }).then(() => {
                    this.getList();
                    this.$message({
                        type: 'success',
                        message: '成功删除!'
                    });
                })
            })
        },
        //设置开关
        setOpen: function(id, open, name) {
            this.http.post('/user/setSwitch', {
                id,
                switch: open,
            }).then(() => {
                if (open == 1) {
                    this.$message({
                        message: name + "已开启！",
                        type: "success"
                    });
                } else {
                    this.$message({
                        message: name + "已关闭！",
                        type: "success"
                    });
                }
            });
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
